import { Checkout } from '@wix/ambassador-ecom-v1-checkout/build/cjs/types.impl';
import { ITip } from '@wix/wix-tips-common';
import { getCheckout } from '../../../api/checkout/CheckoutApi';
import { getTip } from '../../../api/tip/TipApi';
import { SPECS } from '../../../specs';
import type { RootStore } from './RootStore';

const hasSubscriptionItem = (checkout: Checkout) => {
  return checkout.lineItems?.some(
    (lineItem) => lineItem.subscriptionOptionInfo,
  );
};

export const getInitialStateData = async (rootStore: RootStore) => {
  const { httpClient, $props, experiments } = rootStore;
  let checkout: Checkout | undefined;
  let savedTip: ITip | undefined;
  if ($props.checkoutId) {
    checkout = await getCheckout(httpClient, $props.checkoutId);
    if (!checkout || hasSubscriptionItem(checkout)) {
      checkout = undefined;
    } else {
      const purchaseFlowId = checkout?.purchaseFlowId;
      if (purchaseFlowId) {
        savedTip = await getTip(httpClient as any, {
          purchaseFlowId,
        });
        if (experiments.enabled(SPECS.WaitForProps)) {
          rootStore.resolvePageReady?.();
          rootStore.resolvePageReady = undefined;
        }
      }
    }
  }
  return {
    checkout,
    savedTip,
  };
};
