import { RootStore, BindParams } from '../../../RootStore/RootStore';
import {
  SelectedOption,
  ComponentIds as Ids,
  EnabledOptions,
} from '../../../config/constants';
import { autorun, runInAction } from 'mobx';
import { TipTypes } from '@wix/wix-tips-common';
import { SPECS } from '../../../../../specs';

const bind = (rootStore: RootStore): BindParams => {
  const { t, $state, $w, experiments } = rootStore;
  if (experiments.enabled(SPECS.CustomTipAsButton)) {
    return {
      [Ids.CustomValueRepeater]: {
        deleted: () => true,
      },
    };
  }
  const controlSelection = () => {
    const indexToSelect = 0;
    const repeater = $w(Ids.CustomValueRepeater);
    if ($state.selectedOption === SelectedOption.Custom) {
      const alreadySelected = repeater.selectedIndex === indexToSelect;
      /* istanbul ignore else reason: optimization for not set index twice */
      if (!alreadySelected) {
        repeater.selectedIndex = indexToSelect;
      }
    } else {
      repeater.clearSelection();
      runInAction(() => {
        $state.error = undefined;
      });
    }
  };
  autorun(controlSelection);
  return {
    [Ids.CustomValueRepeater]: {
      deleted: () => $state.enabledOptions !== EnabledOptions.All,
      data: () => [{ _id: 'custom' }],
      item: () => {
        return {
          [Ids.CustomValueRepeaterItem]: {
            inputLabel: () => t('checkout.tips.customAmount.selection'),
          },
        };
      },
      onItemSelected: () => {
        if ($state.selectedOption !== SelectedOption.Custom) {
          runInAction(() => {
            $state.selectedTip = { type: TipTypes.Amount, value: '0' };
            $state.isUserSelectedCustomTip = true;
          });
          rootStore.updateTip();
        }
      },
    } as any,
  };
};

export default bind;
